import React from 'react';
import { Box, Text, Heading, AspectRatio } from '@chakra-ui/react';

import InstagramButton from '@/components/Common/InstagramButton';
import WithAnimation from '@/components/Common/WithAnimation';
import Image from '@/components/Common/LazyImage';

import { ASSETS_FLOWER_COUPLE, IMG_COUPLE } from '@/constants/assets';
import {
  GIRL_NAME,
  BOY_NAME,
  IG_GIRL,
  IG_BOY,
  GIRL_PARENT_NAME,
  BOY_PARENT_NAME,
  IS_BOY_FIRST,
} from '@/constants';
import { NAVIGATION_COLOR } from '@/constants/colors';

function SinglePhoto({ ...rest }) {
  const renderText = ({ name, instagramId, parentName, nameProps = {} }) => {
    return (
      <Box>
        <WithAnimation left>
          <Heading
            size="lg"
            lineHeight="90%"
            margin="8px 0 0 0"
            fontWeight="normal"
            fontFamily="CustomFont"
            color="mainColorText"
            dangerouslySetInnerHTML={{ __html: name }}
            {...nameProps}
          />
        </WithAnimation>
        {instagramId && (
          <WithAnimation>
            <InstagramButton
              id={instagramId}
              text={instagramId}
              color="white"
              onlyIcon={false}
              size="md"
              fontFamily="body"
              fontWeight="normal"
              bgColor="bgAlternative"
              letterSpacing="2px"
              boxShadow="xl"
              fontSize="md"
            />
          </WithAnimation>
        )}
        <WithAnimation>
          <Text
            marginTop="16px"
            fontSize="md"
            color="mainColorText"
            dangerouslySetInnerHTML={{ __html: parentName }}
          />
        </WithAnimation>
      </Box>
    );
  };

  const renderBoy = () => {
    return renderText({
      name: BOY_NAME,
      instagramId: IG_BOY,
      parentName: BOY_PARENT_NAME,
    });
  };

  const renderGirl = () => {
    return renderText({
      name: GIRL_NAME,
      instagramId: IG_GIRL,
      parentName: GIRL_PARENT_NAME,
    });
  };

  return (
    <Box padding="32px 0" position="relative" {...rest}>
      <Box
        padding="24px"
        borderRadius="16px"
        border="1px solid"
        borderColor={NAVIGATION_COLOR}
        bgColor="rgb(229 227 207 / 61%)"
        position="relative"
        boxShadow="xl"
      >
        <WithAnimation left>
          <Image
            src={ASSETS_FLOWER_COUPLE}
            height="200px"
            position="absolute"
            top="0"
            left="120px"
            className="animation__flower"
          />
        </WithAnimation>
        <WithAnimation>
          <AspectRatio display="block" zIndex={2} ratio={3 / 4} width="70%" maxW="230px">
            <Image position="relative" zIndex="99" src={IMG_COUPLE} borderRadius="50%" />
          </AspectRatio>
        </WithAnimation>
        <Box padding="16px">
          {IS_BOY_FIRST ? renderBoy() : renderGirl()}
          <Box height="24px" />
          {IS_BOY_FIRST ? renderGirl() : renderBoy()}
        </Box>
      </Box>
    </Box>
  );
}

export default SinglePhoto;
