import React from 'react';
import { string } from 'prop-types';
import { Box, Center, Button, useDisclosure, Heading, Image, Text } from '@chakra-ui/react';
import { TbHandClick } from 'react-icons/tb';

import useLang from '@hooks/useLang';
import ImageHover from '@components/Common/ImageHover';
import WithAnimation from '@/components/Common/WithAnimation';
import ImgDivider from '@/assets/divider.svg';

import { THE_BRIDE } from '@/constants';
import { BUTTON_PADDING_PROPS, BUTTON_PROPS, NAVIGATION_COLOR } from '@/constants/colors';
import { ASSETS_FLOWER_WEDDING, BG_COVER, IMG_GALLERY_1 } from '@/constants/assets';

import txt from './locales';
import Content from './Content';

function PhotoSection() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const lang = useLang();

  return (
    <Box padding="42px 0" bgSize="cover" bgColor="bgSecondary" pos="relative">
      <Image
        src={ASSETS_FLOWER_WEDDING}
        className="animation__flower-secondary"
        position="absolute"
        right="0"
        height="180px"
        zIndex="10"
      />
      <ImageHover
        images={[IMG_GALLERY_1]}
        imageProps={{
          height: '240px',
          width: '240px',
          margin: '8px auto',
          position: 'relative',
          zIndex: '10',
          border: '1px solid',
          borderColor: NAVIGATION_COLOR,
        }}
      />
      <Box
        marginTop="-190px"
        height="230px"
        bgImage={`url(${BG_COVER})`}
        bgSize="cover"
        bgPos="center"
      >
        <Box
          height="100%"
          bg="transparent"
          bgImage="linear-gradient(180deg, #eee3d700 0%, #e5e3cf 100%)"
          transition="background 0.3s, border-radius 0.3s, opacity 0.3s"
          opacity="1"
        />
      </Box>
      <Box padding="0 32px">
        <WithAnimation>
          <Heading
            margin="-20px 0 20px 0"
            fontWeight="normal"
            color="mainColorText"
            textAlign="center"
            size="2xl"
            fontFamily="CustomFont"
            dangerouslySetInnerHTML={{ __html: THE_BRIDE.replace('&', '& <br />') }}
          />
        </WithAnimation>
        <WithAnimation>
          <Text margin="8px 0" textAlign="center" color="mainColorText" fontSize="md">
            “Love bears all things, believes all things, hopes all things, endures all things. Love never fails.”
            <br />
            (1 Corinthians 13:1)
          </Text>
        </WithAnimation>
        {/* Button Section */}
        <WithAnimation>
          <Center>
            <Button
              rightIcon={<TbHandClick size="20px" style={{ marginLeft: '-4px'}} />}
              size="sm"
              margin="16px 0 32px 0"
              letterSpacing="1px"
              onClick={() => onOpen()}
              {...BUTTON_PROPS}
              {...BUTTON_PADDING_PROPS}
            >
              {txt.button[lang]}
            </Button>
          </Center>
        </WithAnimation>
        <WithAnimation>
          <Image src={ImgDivider} margin="auto" maxW="300px" />
        </WithAnimation>
      </Box>
      {/* Content gallery */}
      <Content lang={lang} isOpen={isOpen} onClose={onClose} />
    </Box>
  );
}

PhotoSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(PhotoSection);
