import React from 'react';
import { Box, Heading, Text, Image } from '@chakra-ui/react';

import useLang from '@hooks/useLang';
import useGuestInfo from '@/hooks/useGuestInfo';
import WithAnimation from '@/components/Common/WithAnimation';

import { BOY_NAME, GIRL_NAME } from '@/constants';
import { BG_WELCOME, IMG_LOGO } from '@/constants/assets';
import ImgAndSymbol from '@/assets/icons/and-assets.svg';

import locales from './locales';

function WelcomeV4() {
  const { name } = useGuestInfo();
  const lang = useLang();

  return (
    <Box
      bgColor="bgPrimary"
      bgImage={`url(${BG_WELCOME})`}
      bgSize="cover"
      bgPos="center"
      textAlign="center"
      color="mainColorText"
      padding="42px 0 0 0"
    >
      <Box padding="24px">
        <WithAnimation left>
          <Image src={IMG_LOGO} height="100px" margin="auto" display="block" />
        </WithAnimation>
        <WithAnimation>
          <Heading marginTop="24px" fontWeight="normal" fontFamily="CustomFont">
            {GIRL_NAME}
          </Heading>
        </WithAnimation>
        <WithAnimation left>
          <Image src={ImgAndSymbol} height="60px" margin="auto" display="block" />
        </WithAnimation>
        <WithAnimation>
          <Heading fontWeight="normal" fontFamily="CustomFont">
            {BOY_NAME}
          </Heading>
        </WithAnimation>
        <WithAnimation>
          <Text marginTop="24px">
            Love bears all things, believes all things, hopes all things, endures all things. Love never fails. <br />
            (1 Corinthians 13 : 1)
          </Text>
        </WithAnimation>
      </Box>
      <Box
        margin="32px 0 0 0"
        padding="16px 24px"
        bgColor="bgAlternative"
        border="1px solid"
        borderColor="#98512D"
      >
        {/* Dear Section */}
        <WithAnimation>
          <Box color="alternativeColorText">
            <Text fontSize="sm">{locales.dear[lang]}</Text>
            <Text>{name ? `${name}` : locales.guest[lang]}</Text>
          </Box>
        </WithAnimation>
      </Box>
    </Box>
  );
}

export default React.memo(WelcomeV4);
