import React from 'react';
import { bool, func, string } from 'prop-types';

import {
  Text,
  Box,
  Center,
  Button,
  Heading,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

import { BUTTON_PADDING_PROPS, BUTTON_PROPS } from '@/constants/colors';
import { ENABLE_BANK_TRANSFER } from '@/constants/feature-flags';

import Bank from '@invitato/helpers/dist/constants/banks';

import txtWording from './locales';

function ModalBank({ onClose, visible, onCopyText, lang }) {
  const renderBank = (bank, userName, bankNumber) => {
    return (
      <Box padding="16px 0" fontFamily="serif" textAlign="center">
        <Center>
          <Image src={bank.logo} marginBottom="8px" maxWidth="80px" />
        </Center>
        <Text color="mainColorText" marginTop="0" fontWeight="bold">
          {bankNumber}
        </Text>
        <Text color="black">{userName}</Text>
        <Center>
          <Button
            size="xs"
            fontWeight="normal"
            colorScheme="blackAlpha"
            color="mainColorText"
            variant="outline"
            marginTop="16px"
            onClick={() => onCopyText(bankNumber)}
            {...BUTTON_PADDING_PROPS}
          >
            {txtWording.modalCopy[lang]}
          </Button>
        </Center>
      </Box>
    );
  };

  return (
    <Modal size="full" onClose={onClose} isOpen={visible}>
      <ModalOverlay />
      <ModalContent maxWidth="500px">
        <ModalCloseButton marginTop="12px" {...BUTTON_PROPS} />
        <ModalHeader />
        <ModalBody>
          <Heading
            textAlign="center"
            fontWeight="normal"
            size="lg"
            textTransform="uppercase"
            color="mainColorText"
            margin="24px 0 12px"
          >
            Bank Transfer
          </Heading>
          {ENABLE_BANK_TRANSFER && <>{renderBank(Bank.bca, 'Wendy Lauvensia', '3040436412')}</>}
          <Box height="50px" />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

ModalBank.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
  onCopyText: func.isRequired,
};

ModalBank.defaultProps = {
  lang: 'id',
};

export default ModalBank;
