import React from 'react';
import { string } from 'prop-types';
import { Box, Heading, Image, Text } from '@chakra-ui/react';

import QRCard from '@components/Common/QRCard/Lazy';
import WithAnimation from '@components/Common/WithAnimation';

import useGuestInfo from '@hooks/useGuestInfo';
import useShiftTime from '@hooks/useShiftTime';
import useInvitation from '@/hooks/useInvitation';

import {
  ASSETS_FLOWER_WEDDING,
  // IMG_AKAD,
  // IMG_RECEPTION,
  // IMG_DRESSCODE,
} from '@/constants/assets';
import { ENABLE_MUSLIM_LANGUAGE } from '@/constants/feature-flags';

import {
  WEDDING_AKAD_TIME,
  WEDDING_AKAD_LOC_NAME,
  WEDDING_AKAD_LOC_ROAD,
  WEDDING_AKAD_DRESSCODE,
  WEDDING_RESEPSI_FULLDATE,
  WEDDING_RESEPSI_LOC_NAME,
  WEDDING_RESEPSI_LOC_ROAD,
  WEDDING_RESEPSI_DRESSCODE,
  GOOGLE_MAPS_RESEPSI,
} from '@/constants';

import Detail from './Detail';
import txt from './locales';
import { NAVIGATION_COLOR } from '@/constants/colors';
import { ID_QRCODE } from '@/constants/identifier';

/**
 * function to render WeddingSectionV3 component
 * @returns {JSX.Element}
 */
function WeddingSection({ lang, ...rest }) {
  const isInvitation = useInvitation();
  const shiftTime = useShiftTime();
  const { code } = useGuestInfo();

  return (
    <Box padding="32px 0" width="100%" {...rest}>
      <Image
        src={ASSETS_FLOWER_WEDDING}
        className="animation__flower-secondary"
        position="absolute"
        right="0"
        height="180px"
        zIndex="10"
      />
      <Box
        padding="52px 24px"
        borderRadius="16px"
        border="1px solid"
        borderColor={NAVIGATION_COLOR}
        bgColor="rgb(229 227 207 / 61%)"
        zIndex="2"
        boxShadow="xl"
      >
        <WithAnimation>
          <Heading
            lineHeight="70%"
            fontWeight="normal"
            size="xl"
            textAlign="center"
            color="mainColorText"
            fontFamily="CustomFont"
          >
            <span dangerouslySetInnerHTML={{ __html: txt.title[lang] }} />
          </Heading>
        </WithAnimation>
        <WithAnimation>
          <Text
            textAlign="center"
            fontSize="md"
            margin="16px 0 32px 0"
            color="mainColorText"
            dangerouslySetInnerHTML={{
              __html: isInvitation
                ? 'Cordially invite you to <br />celebrate our Engagement on:'
                : 'Engagement will be held <br />with a limited capacity on: ',
            }}
          />
        </WithAnimation>
        <Detail
          lang={lang}
          items={[
            {
              title: txt.reception[lang],
              date: WEDDING_RESEPSI_FULLDATE,
              time: shiftTime,
              dresscode: WEDDING_RESEPSI_DRESSCODE,
              location: WEDDING_RESEPSI_LOC_NAME,
              locationRoad: WEDDING_RESEPSI_LOC_ROAD,
              locationUrl: isInvitation && GOOGLE_MAPS_RESEPSI,
              info: isInvitation && `<i>*) Fine dining</i>`,
              show: true,
              hideIcon: !isInvitation,
              // imgUrl: IMG_RECEPTION,
              // dresscodeUrl: IMG_DRESSCODE,
            },
            {
              title: ENABLE_MUSLIM_LANGUAGE ? txt.akad[lang] : txt.holy[lang],
              date: `Sunday, 26 November 2023`,
              time: WEDDING_AKAD_TIME,
              dresscode: WEDDING_AKAD_DRESSCODE,
              location: WEDDING_AKAD_LOC_NAME,
              locationRoad: WEDDING_AKAD_LOC_ROAD,
              locationUrl: '',
              info: `<i>*) Misa concept, please be on time.</i>`,
              show: isInvitation,
              // imgUrl: IMG_AKAD,
            },
          ]}
        />
        {code && (
          <div name={ID_QRCODE}>
            <WithAnimation>
              <QRCard marginTop="32px" />
            </WithAnimation>
          </div>
        )}
      </Box>
      <Box className="animation__flower">
        <Image
          marginTop="-180px"
          marginLeft="-64px"
          position="absolute"
          transform="scaleX(-1)"
          src={ASSETS_FLOWER_WEDDING}
          left="0"
          height="180px"
        />
      </Box>
    </Box>
  );
}

WeddingSection.propTypes = {
  lang: string.isRequired,
};

export default React.memo(WeddingSection);